// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n * Copyright (C) 2024 - present Instructure, Inc.\n *\n * This file is part of Canvas.\n *\n * Canvas is free software: you can redistribute it and/or modify it under\n * the terms of the GNU Affero General Public License as published by the Free\n * Software Foundation, version 3 of the License.\n *\n * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY\n * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR\n * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more\n * details.\n *\n * You should have received a copy of the GNU Affero General Public License along\n * with this program. If not, see <http://www.gnu.org/licenses/>.\n */\n\n@media (min-width: 48rem) { /* 768px */\n    .footer__logo__1RM-A {\n        left: 50%;\n        position: absolute;\n        top: calc(100% + 3.4375rem); /* 55px */\n        transform: translateX(-50%);\n    }\n}\n\n@media (min-width: 80rem) { /* 1280px */\n    .footer__logo__1RM-A {\n        bottom: 3.75rem; /* 60px */\n        left: auto;\n        position: fixed;\n        right: 3.75rem; /* 60px */\n        top: unset;\n        transform: none;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"footer__logo": "footer__logo__1RM-A"
};
module.exports = exports;
