/*
 * Copyright (C) 2015 - present Instructure, Inc.
 *
 * This file is part of Canvas.
 *
 * Canvas is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, version 3 of the License.
 *
 * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY
 * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License along
 * with this program. If not, see <http://www.gnu.org/licenses/>.
 */
import $ from 'jquery';
import { externalToolsEnvFor } from '../ExternalToolsEnv';
import { emptyAsNull } from '../../../../util/string-util';
import { addParentFrameContextToUrl } from '../util/addParentFrameContextToUrl';
import tinymce from 'tinymce';
import { isStudioContentItemCustomJson, studioAttributesFrom, displayStyleFrom } from '../../shared/StudioLtiSupportUtils';
export class RceLti11ContentItem {
  static fromJSON(contentItem) {
    let env = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : externalToolsEnvFor(tinymce.activeEditor);
    return new RceLti11ContentItem(contentItem, env);
  }

  constructor(contentItem) {
    let env = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : externalToolsEnvFor(tinymce.activeEditor);
    this.contentItem = contentItem;
    this.env = env;
  }

  get text() {
    return this.contentItem.text;
  }

  get isLTI() {
    var _this$contentItem$med;

    return LTI_MIME_TYPES.includes((_this$contentItem$med = this.contentItem.mediaType) !== null && _this$contentItem$med !== void 0 ? _this$contentItem$med : '');
  }

  get isOverriddenForThumbnail() {
    var _this$contentItem$pla;

    return this.isLTI && this.contentItem.thumbnail && ((_this$contentItem$pla = this.contentItem.placementAdvice) === null || _this$contentItem$pla === void 0 ? void 0 : _this$contentItem$pla.presentationDocumentTarget) === 'iframe';
  }

  get isImage() {
    var _this$contentItem$med2, _this$contentItem$med3;

    return ((_this$contentItem$med2 = this.contentItem.mediaType) === null || _this$contentItem$med2 === void 0 ? void 0 : (_this$contentItem$med3 = _this$contentItem$med2.startsWith) === null || _this$contentItem$med3 === void 0 ? void 0 : _this$contentItem$med3.call(_this$contentItem$med2, 'image')) === true;
  }

  get linkClassName() {
    return this.isOverriddenForThumbnail ? 'lti-thumbnail-launch' : '';
  }

  get url() {
    var _ref;

    return (_ref = this.isLTI ? this.contentItem.canvasURL : this.contentItem.url) === null || _ref === void 0 ? void 0 : _ref.replace(/^(data:text\/html|javascript:)/, '#$1');
  }

  get linkTarget() {
    var _this$contentItem, _this$contentItem$pla2, _this$contentItem$pla3;

    if (this.isOverriddenForThumbnail) {
      return JSON.stringify(this.contentItem.placementAdvice);
    }

    return ((_this$contentItem = this.contentItem) === null || _this$contentItem === void 0 ? void 0 : (_this$contentItem$pla2 = _this$contentItem.placementAdvice) === null || _this$contentItem$pla2 === void 0 ? void 0 : (_this$contentItem$pla3 = _this$contentItem$pla2.presentationDocumentTarget) === null || _this$contentItem$pla3 === void 0 ? void 0 : _this$contentItem$pla3.toLowerCase()) === 'window' ? '_blank' : null;
  }

  get docTarget() {
    var _this$contentItem2, _this$contentItem2$pl, _this$contentItem3, _this$contentItem3$pl, _this$contentItem3$pl2;

    if (((_this$contentItem2 = this.contentItem) === null || _this$contentItem2 === void 0 ? void 0 : (_this$contentItem2$pl = _this$contentItem2.placementAdvice) === null || _this$contentItem2$pl === void 0 ? void 0 : _this$contentItem2$pl.presentationDocumentTarget) === 'embed' && !this.isImage) {
      return 'text';
    } else if (this.isOverriddenForThumbnail) {
      return 'link';
    }

    return (_this$contentItem3 = this.contentItem) === null || _this$contentItem3 === void 0 ? void 0 : (_this$contentItem3$pl = _this$contentItem3.placementAdvice) === null || _this$contentItem3$pl === void 0 ? void 0 : (_this$contentItem3$pl2 = _this$contentItem3$pl.presentationDocumentTarget) === null || _this$contentItem3$pl2 === void 0 ? void 0 : _this$contentItem3$pl2.toLowerCase();
  }

  get codePayload() {
    switch (this.docTarget) {
      case 'iframe':
        return this.generateCodePayloadIframe();

      case 'embed':
        return this.generateCodePayloadEmbed();

      case 'text':
        return this.generateCodePayloadText();

      default:
        return this.generateCodePayloadLink();
    }
  }

  generateCodePayloadIframe() {
    var _this$env, _this$contentItem$cla, _this$contentItem$pla4, _this$contentItem$pla5, _this$contentItem$pla6, _this$contentItem$pla7, _this$contentItem$pla8, _this$contentItem$pla9, _this$contentItem$pla10, _this$contentItem$pla11;

    const studioAttributes = isStudioContentItemCustomJson(this.contentItem.custom) ? studioAttributesFrom(this.contentItem.custom) : null;
    return $('<div/>').append($('<iframe/>', {
      src: addParentFrameContextToUrl(this.url, this.containingCanvasLtiToolId),
      title: this.contentItem.title,
      allowfullscreen: 'true',
      webkitallowfullscreen: 'true',
      mozallowfullscreen: 'true',
      allow: (_this$env = this.env) === null || _this$env === void 0 ? void 0 : _this$env.ltiIframeAllowPolicy,
      ...studioAttributes
    }).addClass((_this$contentItem$cla = this.contentItem.class) !== null && _this$contentItem$cla !== void 0 ? _this$contentItem$cla : '').css({
      width: (_this$contentItem$pla4 = (_this$contentItem$pla5 = this.contentItem.placementAdvice) === null || _this$contentItem$pla5 === void 0 ? void 0 : _this$contentItem$pla5.displayWidth) !== null && _this$contentItem$pla4 !== void 0 ? _this$contentItem$pla4 : '',
      height: (_this$contentItem$pla6 = (_this$contentItem$pla7 = this.contentItem.placementAdvice) === null || _this$contentItem$pla7 === void 0 ? void 0 : _this$contentItem$pla7.displayHeight) !== null && _this$contentItem$pla6 !== void 0 ? _this$contentItem$pla6 : '',
      display: displayStyleFrom(studioAttributes)
    }).attr({
      width: (_this$contentItem$pla8 = (_this$contentItem$pla9 = this.contentItem.placementAdvice) === null || _this$contentItem$pla9 === void 0 ? void 0 : _this$contentItem$pla9.displayWidth) !== null && _this$contentItem$pla8 !== void 0 ? _this$contentItem$pla8 : '',
      height: (_this$contentItem$pla10 = (_this$contentItem$pla11 = this.contentItem.placementAdvice) === null || _this$contentItem$pla11 === void 0 ? void 0 : _this$contentItem$pla11.displayHeight) !== null && _this$contentItem$pla10 !== void 0 ? _this$contentItem$pla10 : ''
    })).html();
  }

  generateCodePayloadEmbed() {
    var _this$contentItem$pla12, _this$contentItem$pla13, _this$contentItem$pla14, _this$contentItem$pla15;

    return $('<div/>').append($('<img/>', {
      src: this.url,
      alt: this.text
    }).css({
      width: (_this$contentItem$pla12 = (_this$contentItem$pla13 = this.contentItem.placementAdvice) === null || _this$contentItem$pla13 === void 0 ? void 0 : _this$contentItem$pla13.displayWidth) !== null && _this$contentItem$pla12 !== void 0 ? _this$contentItem$pla12 : '',
      height: (_this$contentItem$pla14 = (_this$contentItem$pla15 = this.contentItem.placementAdvice) === null || _this$contentItem$pla15 === void 0 ? void 0 : _this$contentItem$pla15.displayHeight) !== null && _this$contentItem$pla14 !== void 0 ? _this$contentItem$pla14 : ''
    })).html();
  }

  generateCodePayloadText() {
    var _this$text;

    return (_this$text = this.text) !== null && _this$text !== void 0 ? _this$text : '';
  }

  get containingCanvasLtiToolId() {
    return this.env.containingCanvasLtiToolId;
  }

  get currentTinyMceSelection() {
    return this.env.editorSelection;
  }

  generateCodePayloadLink() {
    const $linkContainer = $('<div/>'),
          $link = $('<a/>', {
      href: this.url,
      title: this.contentItem.title,
      target: this.linkTarget
    });

    if (this.linkClassName) {
      $link.addClass(this.linkClassName);
    }

    $linkContainer.append($link);

    if (this.contentItem.thumbnail) {
      var _this$contentItem$thu, _this$contentItem$thu2;

      $link.append($('<img />', {
        src: this.contentItem.thumbnail['@id'],
        height: (_this$contentItem$thu = this.contentItem.thumbnail.height) !== null && _this$contentItem$thu !== void 0 ? _this$contentItem$thu : 48,
        width: (_this$contentItem$thu2 = this.contentItem.thumbnail.width) !== null && _this$contentItem$thu2 !== void 0 ? _this$contentItem$thu2 : 48,
        alt: this.text
      }));
    } else if (emptyAsNull(this.currentTinyMceSelection) != null && $link[0] != null) {
      var _this$currentTinyMceS;

      $link[0].innerHTML = (_this$currentTinyMceS = this.currentTinyMceSelection) !== null && _this$currentTinyMceS !== void 0 ? _this$currentTinyMceS : '';
    } else {
      var _this$generateLinkHtm;

      // don't inject tool provided content into the page HTML
      $link.text((_this$generateLinkHtm = this.generateLinkHtml()) !== null && _this$generateLinkHtm !== void 0 ? _this$generateLinkHtm : '');
    }

    return $linkContainer.html();
  }

  generateLinkHtml() {
    var _ref2, _emptyAsNull, _this$contentItem$tex, _this$contentItem4, _this$contentItem4$ti;

    return (_ref2 = (_emptyAsNull = emptyAsNull(this.currentTinyMceSelection)) !== null && _emptyAsNull !== void 0 ? _emptyAsNull : emptyAsNull((_this$contentItem$tex = this.contentItem.text) === null || _this$contentItem$tex === void 0 ? void 0 : _this$contentItem$tex.trim())) !== null && _ref2 !== void 0 ? _ref2 : (_this$contentItem4 = this.contentItem) === null || _this$contentItem4 === void 0 ? void 0 : (_this$contentItem4$ti = _this$contentItem4.title) === null || _this$contentItem4$ti === void 0 ? void 0 : _this$contentItem4$ti.trim();
  }

}
const LTI_MIME_TYPES = ['application/vnd.ims.lti.v1.ltilink', 'application/vnd.ims.lti.v1.launch+json'];
/**
 * Declare the global tinyMCE information used to pass editor context around in Canvas.
 *
 * Eventually, this should be moved into packages/canvas-rce.
 */