// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n * Copyright (C) 2024 - present Instructure, Inc.\n *\n * This file is part of Canvas.\n *\n * Canvas is free software: you can redistribute it and/or modify it under\n * the terms of the GNU Affero General Public License as published by the Free\n * Software Foundation, version 3 of the License.\n *\n * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY\n * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR\n * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more\n * details.\n *\n * You should have received a copy of the GNU Affero General Public License along\n * with this program. If not, see <http://www.gnu.org/licenses/>.\n */\n\n/* TODO make mostly/fully InstUI? */\n.loginLogo__2HCx0 {\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    gap: 0.75rem; /* 12px */\n    justify-content: center;\n    margin: 0 auto;\n    text-align: center;\n\n    img {\n        display: block;\n        height: 3.4375rem; /* 55px */\n        object-fit: contain;\n        width: 11.25rem; /* 180px */\n    }\n\n    .loginLogo__desc__nflZ3 {\n        font-size: 0.875rem; /* 14px */\n        margin-top: 0.5rem; /* 8px */\n    }\n\n    @media (min-width: 48rem) {\n        img {\n            height: 4.0625rem; /* 65px */\n            width: 13.75rem; /* 220px */\n        }\n    }\n\n    @media (min-width: 80rem) {\n        img {\n            height: 5.3125rem; /* 85px */\n            width: 16.25rem; /* 260px */\n        }\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"loginLogo": "loginLogo__2HCx0",
	"loginLogo__desc": "loginLogo__desc__nflZ3"
};
module.exports = exports;
