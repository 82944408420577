// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n * Copyright (C) 2024 - present Instructure, Inc.\n *\n * This file is part of Canvas.\n *\n * Canvas is free software: you can redistribute it and/or modify it under\n * the terms of the GNU Affero General Public License as published by the Free\n * Software Foundation, version 3 of the License.\n *\n * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY\n * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR\n * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more\n * details.\n *\n * You should have received a copy of the GNU Affero General Public License along\n * with this program. If not, see <http://www.gnu.org/licenses/>.\n */\n\n\n.contentLayout__3iLMn {\n    @media (min-width: 48rem) and (max-width: 80rem) { /* 768px – 1280px */\n        padding: 5rem 0 8rem; /* 80px 128px */\n    }\n}\n\n.contentLayout__wrapper__2zTIp {\n    height: 100%;\n    padding: 2.25rem 1.5rem; /* 36px 24px */\n\n    @media (min-width: 48rem) { /* 768px */\n        height: unset;\n        margin: 0 auto;\n        padding: 3.75rem 6.25rem; /* 60px 100px */\n        width: 35.75rem; /* 572px */\n    }\n\n    @media (min-width: 80rem) { /* 1280px */\n        height: 100%;\n        margin: unset;\n        padding: 5rem; /* 80px */\n        width: 45rem; /* 720px */\n    }\n}\n\n.contentLayout__background__11nNL {\n    bottom: 0;\n    left: 0;\n    position: absolute;\n    right: 0;\n    top: 0;\n    z-index: -1;\n\n    @media (min-width: 80rem) { /* 1280px */\n        left: 45rem; /* 720px */\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"contentLayout": "contentLayout__3iLMn",
	"contentLayout__wrapper": "contentLayout__wrapper__2zTIp",
	"contentLayout__background": "contentLayout__background__11nNL"
};
module.exports = exports;
